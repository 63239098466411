<template>
    <WidgetWrapper :widget="widget">
        <Files
            isMyFiles
            widgetEmbed
            isFounder
            :showFilter="false"
            :fileDragCreate="false"
            isStudent />
    </WidgetWrapper>
</template>

<script>
import Files from '@apps/vue2Files'
import WidgetWrapper from '../WidgetWrapper.vue'
export default {
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    components: {
        Files,
        WidgetWrapper
    }
}
</script>